import { observable, action, makeAutoObservable, runInAction } from 'mobx';
import { IUserInfo, IUserSelectInfo } from 'model/IUser';
import { getUserSelectList } from 'api/user';
import { EnumRuleStatus, IRuleInfo } from 'model/IRole';
import { getRuleList } from 'api/role';
import { getTreeList } from 'utils/general';
import { IDepartmentTree } from 'model/IDepartment';

export default class UserStore {
    @observable public userInfo: IUserInfo;
    @observable public userList: IUserSelectInfo[];
    @observable public userDeptTree: IDepartmentTree[];
    @observable public ruleList: IRuleInfo[];
    @observable public ruleTree: IRuleInfo[];

    constructor() {
        makeAutoObservable(this)
        this.userInfo = {username:'', token: ''}
        this.userList = []
        this.userDeptTree = []
        this.ruleList = []
        this.ruleTree = []
    }

    @action public setUserInfo = (user: IUserInfo) => {
        this.userInfo = user;
    }
    @action public getUserSelectList = async () => {
        const result = await getUserSelectList();
        if(result && result.data) {
            runInAction(() => {
                this.userList = result.data.users
                let depttree = getTreeList(result.data.depts, ['value','id'], ['title','name'])
                // console.log(JSON.stringify(depttree))
                this.userDeptTree = depttree
            })
        }
    }
    @action public getRuleList = async () => {
       const result = await getRuleList();
        if(result && result.data) {
            runInAction(() => {
                this.ruleList = result.data
                // console.log(JSON.stringify(getTreeList(this.ruleList, ['key', 'id'], ['title', 'title'])))
                let showrule = result.data.filter( item => item.status === EnumRuleStatus.SHOW)
                let showTree = getTreeList(showrule, ['key', 'id'], ['title', 'title'])
                showTree = this.setKeyString(showTree)
                // console.log(JSON.stringify(showTree))
                this.ruleTree = showTree
            })
        }
    }

    private setKeyString(list) {
        for(let tree of list) {
            tree.key = tree.key + ''
            if(tree.children) {
                tree.children = this.setKeyString(tree.children)
            }
        }
        return list;
    }

    get UserInfo(): IUserInfo {
        return this.userInfo;
    }
    get UserList(): IUserSelectInfo[] {
        return this.userList;
    }
    get UserDeptTree(): IDepartmentTree[] {
        return this.userDeptTree;
    }
    get RuleList(): IRuleInfo[] {
        return this.ruleList;
    }
    get RuleTree(): IRuleInfo[] {
        return this.ruleTree;
    }
    get UserPermission() :string[] {
        if(this.userInfo.uri) {
            return this.userInfo.uri
        }
        return []
    }
}