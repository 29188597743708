const Cookie = {
    setCookie(cname, cvalue, time){
        cname = `pm_${cname}`;
        if (time) {
            let expires = ";expires="+new Date(time);
            document.cookie = cname+"="+cvalue+expires;
        } else {
            document.cookie = cname+"="+cvalue;
        }
    },
    getCookie(cname){
        cname = `pm_${cname}`;
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i=0; i<ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(name)===0) { return c.substring(name.length,c.length); }
        }
        return "";
    }
}

export default Cookie;