//权限信息状态
export enum EnumRuleStatus {
    HIDE = -1,
    SHOW = 1
}

//权限信息
export interface IRuleInfo {
    pid: number;
    id: number;
    title: string;
    uri: string;
    status: EnumRuleStatus;
    children?: IRuleInfo[];
    key: number;
}

//角色信息
export interface IRoleInfo {
    id: number;
    name: string;
    rules: any;
}