import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_terrace.css';
import { gantt } from 'dhtmlx-gantt';

let createCalBack: Function | null = null;

export function setCallBack(add) {
    createCalBack = add;
}

gantt.i18n.setLocale("cn");
gantt.i18n.setLocale({
    labels: {
        new_task: "创建新任务"
    }
});
gantt.plugins({
    // tooltip: true,
    marker: true
});

gantt.config.drag_move = false;
gantt.config.drag_progress = false;
gantt.config.show_links = false;
gantt.config.details_on_dblclick = false;
gantt.config.show_errors = false;
gantt.config.show_markers = true;

// gantt.config.placeholder_task = true;
gantt.config.reorder_grid_columns = true;
gantt.config.scale_height = 30*3;
gantt.config.min_column_width = 40;

gantt.serverList("milestone", [
    {key:'0', label: "否"},
    {key:'1', label: "是"}
]);

//设置工作时间
gantt.config.work_time = true;
gantt.setWorkTime({day: 6, hours: false}); //make Saturdays day-off
gantt.setWorkTime({day: 0, hours: false}); //make Sundays day-off

//禁止时间轴拖拽
gantt.config.drag_timeline = null;

//设置时间格式
let weekScaleTemplate = (date) => {
    let dateToStr = gantt.date.date_to_str("%M%d");
    let weekNum = gantt.date.date_to_str("(week%W)");
    let endDate = gantt.date.add(gantt.date.add(date,1,"week"), - 1, "day");
    return dateToStr(date)+"-"+ dateToStr(endDate)+""+ weekNum(date);
};
gantt.config.scales = [
    { unit:"month", step:1, date:"%Y %F"},
    { unit:"week", step:1, template: weekScaleTemplate},
    { unit:"day", step:1, date: "%d %D"}
];

//修改grid头部样式
gantt.templates.grid_header_class = (columnName, column) => {
    return "mygrantt-header";
};
//修改grid行样式
gantt.templates.grid_row_class = (start_date, end_date, item) => {
    if (item.$index % 2 === 0) return "whiteSmoke";//偶数行,标浅黄色底色
    // if (item.progress == 0) return "nobegin";/*未开始的*/
    // if (item.progress >= 1) return "green";/* 已完成的*/
    return "";
};
//实现工作时间突出周末颜色
gantt.templates.timeline_cell_class = (item, date) => {
    if(date && (date.getDay() === 0 || date.getDay() === 6)) {
        return "mygrantt-weekend";
    }
    return "";
}
//设置任务颜色
gantt.templates.task_class = (start, end, task) => {
    // console.log(task)
    let planStartDate = task.plannedStartDate ? new Date(task.plannedStartDate) : null;
    let planEndDate = task.plannedFinishDate ? new Date(task.plannedFinishDate) : null;
    let actStartDate = task.actStartDate ? new Date(task.actStartDate) : null;
    let actEndDate = task.actFinishDate ? new Date(task.actFinishDate) : null;
    if((planStartDate&&actStartDate&&planStartDate.getTime()<actStartDate.getTime()) ||
        (planEndDate&&actEndDate&&actEndDate.getTime()<planEndDate.getTime())
    ) {
        return 'timelint-color1';
    }
    if((planStartDate&&actStartDate&&planStartDate.getTime()>actStartDate.getTime()) ||
        (planEndDate&&actEndDate&&actEndDate.getTime()>planEndDate.getTime())
    ) {
        return 'timelint-color2';
    }
    return "";
};

//设置提示框
// gantt.templates.tooltip_date_format = (date) => {
//     let formatFunc = gantt.date.date_to_str("%Y-%m-%d");
//     return formatFunc(date);
// };
// gantt.templates.tooltip_text = (start, end, task: TypeTaskData) => {
//     return "<b>名称:</b> " + task.text + "<br/><b>开始时间:</b> " +
//         gantt.templates.tooltip_date_format(start) +
//         "<br/><b>结束时间:</b> " + gantt.templates.tooltip_date_format(end) + "<br/><b>执行人:</b>" + this.executorLabel(task)
// };

//弹框
// gantt.locale.labels.section_text = "任务名称";
// gantt.locale.labels.section_plannedDate = "计划开始完成时间";
// gantt.config.lightbox.sections = [
//     {name: "text", height: 40, map_to: "text", type: "textarea", focus: true},
//     {name: "plannedDate", height: 40, map_to:{start_date:"plannedStartDate",end_date:"plannedFinishDate"}, type: "time", time_format: ["%Y", "%m", "%d"]},
// ]

// let mapping = {
//     init: (inlineEditors) => {
//         gantt.attachEvent("onTaskDblClick", (id, e) => {
//             var cell = inlineEditors.locateCell(e.target);
//             if (cell && inlineEditors.getEditorConfig(cell.columnName)) {
//                 inlineEditors.startEdit(cell.id, cell.columnName);
//                 return false;
//             }
//             return true;
//         },"");
//         gantt.attachEvent("onEmptyClick", () => {
//             inlineEditors.hide();
//             return true;
//         },"");
//     },

//     onShow: (inlineEditors, node) => {

//         node.onkeydown = (e) => {
//             e = e || window.event;
//             if(e.defaultPrevented){
//                 return;
//             }

//             let keyboard = gantt.constants.KEY_CODES;

//             let shouldPrevent = true;
//             switch (e.keyCode) {
//                 case gantt.keys.edit_save:
//                     inlineEditors.save();
//                     break;
//                 case gantt.keys.edit_cancel:
//                     inlineEditors.hide();
//                     break;
//                 case keyboard.TAB:
//                     if(e.shiftKey){
//                         inlineEditors.editPrevCell(true);
//                     }else{
//                         inlineEditors.editNextCell(true);
//                     }
//                     break;
//                 default:
//                     shouldPrevent = false;
//                     break;
//             }

//             if(shouldPrevent){
//                 e.preventDefault();
//             }
//         };
//     },

//     onHide: (inlineEditors, node) => {}
// };
// gantt.ext.inlineEditors.setMapping(mapping);

gantt.config.layout = {
    css: "gantt_container",
    cols: [
        {
            width: 800,
            min_width: 300,
            rows:[
                {view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer"},
                {view: "scrollbar", id: "gridScroll", group:"horizontal"}
            ]
        },
        {resizer: true, width: 1},
        {
            rows:[
                {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
                {view: "scrollbar", id: "scrollHor", group:"horizontal"}
            ]
        },
        {view: "scrollbar", id: "scrollVer"}
    ]
    
};

// gantt.attachEvent("onParse", () => {		
//     gantt.eachTask((task:TypeTaskData) => {
//         task.progress = task.degreeOfCompletion;
//     });
// }, "");

gantt.attachEvent("onTaskCreated", (task) => {
    console.log(task)
    // if(task.type === gantt.config.types.placeholder){
    //     task.text = "创建新任务";
    // }
    if(createCalBack) {
        createCalBack()
    }
    return false;
},"");

gantt.attachEvent("onTaskClick", (task) => {
    // console.log(gantt.getTask(task))
},"")