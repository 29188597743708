//比较2个包围盒是否包含
export function isContainRect(min:React.RefObject<HTMLDivElement>, max:React.RefObject<HTMLDivElement>): boolean {
    let minRect = min&&min.current ? min.current.getBoundingClientRect() : null;
    let maxRect = max&&max.current ? max.current.getBoundingClientRect() : null;
    // console.log('minRect', minRect)
    // console.log('maxRect', maxRect)
    if(minRect && maxRect && minRect.top >= maxRect.top && minRect.bottom <= maxRect.bottom &&
        minRect.left >= maxRect.left - 50 && minRect.right <= maxRect.right + 50) {
        return true;
    }
    return false;
}

//验证手机号
export function checkPhoneNum(value) {
    let reg = "^1[0-9]{10}$";//手机号码验证regEx:第一位数字必须是1，11位数字
    let re = new RegExp(reg);
    return re.test(value)
}

//验证身份证
export function checkIDCard(value) {
    let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    let re = new RegExp(reg);
    return re.test(value)
}  

//封装树形数据
function getchilds(id:number, array:any[], keyProperty:string[], valueProperty:string[]) {
    let childs: any[] = [];
    for (let arr of array) {//循环获取子节点
        if (arr.pid === id) {
            let child = { ...arr }
            child[keyProperty[0]] = arr[keyProperty[1]];
            child[valueProperty[0]] = arr[valueProperty[1]];
            childs.push(child);
        }
    }
    for (let child of childs) {//获取子节点的子节点
        let childscopy = getchilds(child.value, array, keyProperty, valueProperty);//递归获取子节点
        if (childscopy.length > 0) {
            child.children = childscopy;
        }
    }
    return childs;
}
export function getTreeList(list:any[], keyProperty:string[], valueProperty:string[]) {
    let result: any[] = [];
    for (let item of list) {
        if (item.pid === 0) {//判断是否为顶层节点
            let parent = { ...item }
            parent[keyProperty[0]] = item[keyProperty[1]];
            parent[valueProperty[0]] = item[valueProperty[1]];
            parent['children'] = getchilds(item.id, list, keyProperty, valueProperty);//获取子节点
            result.push(parent);
        }
    }
    return result;
}

//数组合并去重
export function getConcatUniqArray(arr1,arr2) {
    let arr = arr1.concat();
    for(let i=0;i<arr2.length;i++){
        if(arr.indexOf(arr2[i]) < 0) {
            arr.push(arr2[i])
        }
    }
    return arr;
}

//获取url?后参数
export function getQueryString(name) { 
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
    var r = window.location.search.substr(1).match(reg); 
    if (r != null) return unescape(r[2]); 
    return null; 
}

//下载
export function downloadFile(url) {
    let a = document.createElement('a');
    //需要下载的数据内容,我这里放的就是BLOB，如果你有下载链接就不需要了
    // let blob = new Blob([content],{'type':'application/vnd.ms-excel;charset=UTF-8'});  
    // let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.target = "_blank";
    // a.download = filename;
    a.click();
    // window.URL.revokeObjectURL(url);
}

//判断是否为移动端
export function isPhone() {
    if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
       return true;
    } else {
        return false;
    }
}