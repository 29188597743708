import request from '../utils/request';

//权限列表
export async function getRuleList() {
    return await request('auth/rule/all', {
        method: 'GET'
    })
}

//根据角色获取权限
export async function getRulesByRole(roleId: number) {
    return await request('auth/role/rule_by_id', {
        method: 'GET',
        body: {roleId}
    })
}

//角色列表
export async function getRoleList() {
    return await request('auth/role/list', {
        method: 'GET'
    })
}

//角色新增
export async function addRole(params: {name:string, rules:number[]}) {
    return await request('auth/role/add', {
        method: 'POST',
        body: params
    })
}

//角色编辑
export async function editRole(params: {id:number, name:string, rules:number[]}) {
    return await request('auth/role/edit', {
        method: 'PUT',
        body: params
    })
}

//角色删除
export async function deleteRole(id:number) {
    return await request('auth/role/delete', {
        method: 'DELETE',
        body: {id}
    })
}