import { ITableQuery } from 'model/Common';
import { ILoginParams, IUserCostInfo, IUserManagerInfo, IUserPwdParams } from '../model/IUser';
import request, { downloadRequest } from '../utils/request';

//登录
export async function login(params:ILoginParams) {
    return await request('auth/user/login', {
        method: 'POST',
        body: params
    })
}
//修改密码
export async function updatePassword(params: IUserPwdParams) {
    return await request('auth/user/change_password', {
        method: 'PUT',
        body: params
    })
}
//微信解绑
export async function unbindWechat() {
    return await request('auth/user/unbind_wechat', {
        method: 'POST',
        body: ''
    })
}

//获取当前登录用户权限
export async function getAuthPermission() {
    return await request('auth/user/permission', {
        method: 'GET'
    })
}

//人员列表(用于人员选择)
export async function getUserSelectList() {
    return await request('auth/user/list', {
        method: 'GET'
    })
}
//============================微信扫码登录==============================
//获取二维码的唯一token
export async function getQRcodeToken() {
    return await request('auth/wechat/login/qrcode', {
        method: 'POST'
    })
}

//判断该用户是否绑定
export async function checkBind(params) {
    return await request('auth/wechat/login/scan_result', {
        method: 'GET',
        body: params
    })
}

//绑定微信
export async function bindWechat(params) {
    return await request('auth/wechat/login/bind', {
        method: 'POST',
        body: params
    })
}

//微信登录
export async function loginByWechat(token) {
    return await request('auth/wechat/login/confirm', {
        method: 'POST',
        body: {token}
    })
}

//获取用户是否完成微信登录
export async function checkWechatLogin(params) {
    return await request('auth/wechat/login/scan_info', {
        method: 'GET',
        body: params
    })
}

//============================人员管理==============================

//人员列表
export async function getUserManagerList(params: ITableQuery) {
    return await request('auth/user/management', {
        method: 'GET',
        body: params
    })
}

//人员新增
export async function addUser(params: IUserManagerInfo) {
    return await request('auth/user/add', {
        method: 'POST',
        body: params
    })
}

//人员编辑
export async function editUser(params: IUserManagerInfo) {
    return await request('auth/user/edit', {
        method: 'PUT',
        body: params
    })
}

//人员删除
export async function deleteUser(id: number) {
    return await request('auth/user/delete', {
        method: 'DELETE',
        body: {id}
    })
}

//密码重置
export async function resetPassword(uid: number) {
    return await request('auth/user/reset_password', {
        method: 'PUT',
        body: {uid}
    })
}

//人员下载
export async function exportUser() {
    return await downloadRequest('auth/user/export_list', {
        method: 'GET'
    })
}

//============================人员费率==============================
interface ICostQuery {
    page: number;
    size: number;
    realname?: string;
    uid?: number;
}
//列表
export async function getUserCostList(params: ICostQuery) {
    return await request('auth/user_payrate/list', {
        method: 'GET',
        body: params
    })
}

//查看个人费率
export async function getUserCostDetail(uid: number) {
    return await request('auth/user_payrate/history_list', {
        method: 'GET',
        body: { uid }
    })
}

//新增
export async function addUserCost(params: IUserCostInfo) {
    return await request('auth/user_payrate/add', {
        method: 'POST',
        body: params
    })
}

//删除
export async function deleteUserCost(ids: number[]) {
    let url = 'auth/user_payrate/delete?'
    for(let id of ids) {
        url = `${url}ids=${id}`
    }
    return await request(url, {
        method: 'DELETE',
    })
}

//费率下载
export async function exportUserCost() {
    return await downloadRequest('auth/user_payrate/export_list', {
        method: 'GET'
    })
}