/**
 * 统一接口封装
 */
import { message } from 'antd';
import qs from 'qs';
import { getBaseUrl } from './global';
import Cookie from "./cookie";
import store from 'store';
const version = 'v1';
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
interface IRequestOption {
    method?: string;
    body?: any;
    headers?: any;
    credentials?: any;
}

export default function request(url: string, options: object) {
    url = version ? `${getBaseUrl()}/${version}/${url}` : `${getBaseUrl()}/${url}`;
    let token = ''
    if(Cookie.getCookie('token')) {
        token = Cookie.getCookie('token')
    } else {
        token = store.user.UserInfo.token
    }
    const defaultOptions = {
        credentials: 'include',
        headers: {
            Authorization: token
        }
    };
    const newOptions: IRequestOption = { ...defaultOptions, ...options };
    if(newOptions.method === 'GET') {
        if(newOptions.body) {
            //排序sort处理
            let sortParams = ''
            let sortlist = newOptions.body.sort
            if(sortlist) {
                sortlist = sortlist.split(';')
                for(let s of sortlist) {
                    sortParams += '&sort=' + s
                }
            }
            const { sort, ...other } = newOptions.body
            url = `${url}?${qs.stringify(other)}${sortParams}`
            delete newOptions.body
        }
    } else {
        if (newOptions.body instanceof FormData) {
            // newOptions.body is FormData
            newOptions.headers = {
                ...newOptions.headers,
            };
        } else {
            newOptions.headers = {
                'Content-Type': 'application/json; charset=utf-8',
                ...newOptions.headers,
            };
            newOptions.body = JSON.stringify(newOptions.body);
        }
    } 

    return fetch(url, newOptions)
        .then( response => {
            return response.json()
        })
        .then( data => {
            if(data.code === 0) {
                return Promise.resolve(data);
            } else if(data.code !== 500) {
                if(data.code === 401) { //重新登录
                    window.location.href = "#login";
                    return;
                }
                if(data.code === 403) {
                    return;
                }
                message.error({
                    content: data.message,
                })
            } else if(data.code === 500) {
                message.error({
                    content: '服务器请求异常!',
                })
            }
        })
}

export function downloadRequest(url: string, options: object) {
    url = version ? `${getBaseUrl()}/${version}/${url}` : `${getBaseUrl()}/${url}`;
    let token = ''
    if(Cookie.getCookie('token')) {
        token = Cookie.getCookie('token')
    } else {
        token = store.user.UserInfo.token
    }
    return `${url}?Authorization=${encodeURIComponent(token)}`
}