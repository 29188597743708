import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

const LoginPage = React.lazy(() => import('views/Login/Auth'));
const MainPage = React.lazy(() => import('layouts/MainFrame'));
const WechatBindPage = React.lazy(() => import('views/Login/WechatBind'));

const RouterContainer: React.FC = () => (
    <React.Suspense fallback={<div></div>}>
        <HashRouter>
          <Switch>
            <Route path="/wechatbind"
              component={WechatBindPage}
            />
            <Route path="/login"
              component={LoginPage}
            />
            <Route path="/"
              component={MainPage}
            />
          </Switch>
      </HashRouter>
    </React.Suspense>
);

export default RouterContainer;