export function getBaseUrl() {
    return process.env.REACT_APP_BASE_URL;
}

export function getHostUrl() {
    let host = window.location.origin;
    if(process.env.NODE_ENV === 'development') {
        host = 'http://t-pm.biaddti.com'
    }
    return host;
}

export const RSAPublicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ=='

export const PageSizeOptions = ['50', '100']

export const PermissionUri = {
    Department: {
        List: '/v1/auth/dept/list',
        Add: '/v1/auth/dept/add',
        Edit: '/v1/auth/dept/edit',
        Delete: '/v1/auth/dept/delete'
    },
    Role: {
        List: '/v1/auth/role/list',
        Add: '/v1/auth/role/add',
        Edit: '/v1/auth/role/edit',
        Delete: '/v1/auth/role/delete'
    },
    Workbench: {
        List: '/workbench'
    },
    Project: {
        List: '/v1/project/list',
        Add: '/v1/project/add',
        Edit: '/v1/project/edit',
        Delete: '/v1/project/delete',
        Target: 'projectObjective',
        Desc: '/v1/project/planning/detail',
        Business: '/v1/project/business/detail',
        ExportList: '/v1/project/export_list',
        ExportPlane: '/v1/user_plan/export_list_by_datescope',
        UserPlanSta: '/v1/project/user_plan/list',
        GanttList: '/v1/project_gantt/list'
    },
    User: {
        List: '/v1/auth/user/management',
        Add: '/v1/auth/user/add',
        Edit: '/v1/auth/user/edit',
        Delete: '/v1/auth/user/delete',
        ResetPassword: '/v1/auth/user/reset_password',
        ExportList: '/v1/auth/user/export_list',
    },
    UserCost: {
        List: '/v1/auth/user_payrate/list',
        Import: '/v1/auth/user_payrate/import_list',
        Export: '/v1/auth/user_payrate/export_list',
        Detail: '/v1/auth/user_payrate/history_list',
        Add: '/v1/auth/user_payrate/add',
        Delete: '/v1/auth/user_payrate/delete',
    },
    Statistics: {
        Plane: '/v1/user_plan/statistics'
    },
    Config: {
        Dict: '/v1/config/selectKeys',
        DictEdit: '/v1/config/updateSelectValue'
    }
}