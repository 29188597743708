import UserStore from './StoreUser';
import ProjectStore from './StoreProject';

interface IStore {
    user: UserStore,
    project: ProjectStore,
    initData: () => void;
}

class Store {
    user: UserStore
    project: ProjectStore

    constructor() {
        this.user = new UserStore();
        this.project = new ProjectStore();
    }

    initData = () => {
        this.user.getUserSelectList()
    }
}
export default new Store();
export type {IStore}