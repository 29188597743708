import { observable, action, makeAutoObservable } from 'mobx';

type ProjectInfo = {
    projectId: number;
    projectName: string;
    code: string;
    desc: boolean,
    target: boolean,
    userPlanSta: boolean,
    gantt: boolean;
} 

export default class UserProject {
    @observable public currentProject: ProjectInfo;
    @observable public fromWorkbench: boolean; //入口是工作台，用于判断提交接口

    constructor() {
        makeAutoObservable(this)
        this.currentProject = {
            projectId:0, 
            projectName: '',
            code: '',
            desc: false,
            target: false,
            userPlanSta: false,
            gantt: false
        }
        this.fromWorkbench = false
    }

    @action public setCurrentProject = (data: ProjectInfo) => {
        this.currentProject = data;
    }
    
    @action public setFromWorkbench = (isfrom: boolean) => {
        this.fromWorkbench = isfrom;
    }

    get CurrentProject(): ProjectInfo {
        return this.currentProject;
    }
    get FromWorkbench(): boolean {
        return this.fromWorkbench;
    }
}